import 'initializers/__webpack_public_path__'

import { useEffect, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'

import { Errors, Form } from '@dropscan/forms'
import HtmlForm from '@dropscan/forms/HtmlForm'
import { useNotifications } from '@dropscan/notifications'

import Separator from '@dropscan/ds/components/Separator'
import { Box } from '@dropscan/ds/core/Box'
import { Flex } from '@dropscan/ds/core/Flex'
import Text from '@dropscan/ds/core/Text'
import { Button, ButtonRow, Checkbox, PasswordInput, TextInput } from '@dropscan/ds/forms'

import { DocumentTitle } from 'customers/components/DocumentTitle'
import NotificationList from 'customers/components/NotificationList'
import { FormContainer, LoginLayout } from 'customers/layouts/LoginLayout'

import { AppShell } from './AppShell'

interface LoginParams {
  'user[email]': string
  'user[password]': string
  'user[remember_me]': boolean
  'g-recaptcha-response': string
}

export interface LoginAppProps {
  recaptchaSiteKey: string | null
  initialFormData: LoginParams
  initialFormErrors: Errors<LoginParams>
}

export const LoginApp = ({
  initialFormData,
  initialFormErrors,
  recaptchaSiteKey,
}: LoginAppProps) => (
  <AppShell>
    <DocumentTitle>Dropscan - Anmelden</DocumentTitle>
    <NotificationList />
    <LoginLayout heading="Willkommen zurück bei Ihrem Dropscan-Konto.">
      <HtmlForm initialData={initialFormData} initialErrors={initialFormErrors} action="/login">
        {form => <LoginForm recaptchaSiteKey={recaptchaSiteKey} {...form} />}
      </HtmlForm>
    </LoginLayout>
  </AppShell>
)

const LoginForm = ({
  recaptchaSiteKey,
  field,
  meta: { submitting, validationState, touched },
  submit,
}: Form<LoginParams, {}> & Pick<LoginAppProps, 'recaptchaSiteKey'>) => (
  <FormContainer>
    <TextInput type="email" required labelText="E-Mail-Adresse" {...field('user[email]').props} />
    <PasswordInput required labelText="Passwort" {...field('user[password]').props} />
    {recaptchaSiteKey && (
      <div suppressHydrationWarning>
        <Flex flexDirection="column" alignItems="center">
          <Recaptcha
            sitekey={recaptchaSiteKey}
            onChange={token => field('g-recaptcha-response').props.onChangeValue(token || '')}
          />
        </Flex>
      </div>
    )}
    <Checkbox
      css={{ margin: 'auto' }}
      labelText="Ich möchte für 30 Tage angemeldet bleiben"
      {...field('user[remember_me]').props}
    />
    <InlineErrorNotification clearErrors={touched} />
    <Separator my={0} />
    <ButtonRow>
      <Box mr={4} css={{ whiteSpace: 'nowrap' }}>
        <Text fontSize={1}>
          Passwort vergessen? <a href="/password/new">Wir schicken es Ihnen</a>
        </Text>
        <Text fontSize={1}>
          Noch kein Dropscan-Konto? <a href="/signup">Kostenlos registrieren</a>
        </Text>
      </Box>
      <Button
        id="login_submit"
        color="primary"
        onClick={submit}
        running={submitting}
        disabled={validationState === 'error'}
      >
        Anmelden
      </Button>
    </ButtonRow>
  </FormContainer>
)

function InlineErrorNotification({ clearErrors }: { clearErrors: boolean }) {
  const notifications = useNotifications()
  const [errors, setErrors] = useState<typeof notifications>([])
  useEffect(() => {
    const es = notifications.filter(n => n.priority === 'error')
    if (es.length > 0) {
      // the normal notification list removes errors, but we want them to persist
      setErrors(es)
    }
  }, [notifications])

  useEffect(() => {
    if (clearErrors) {
      setErrors([])
    }
  }, [clearErrors])

  if (!errors.length) {
    return null
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      {errors.map(({ children }) => (
        <Text fg="danger-400">{children}</Text>
      ))}
    </Flex>
  )
}
